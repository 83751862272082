import './App.css';
import Editor from './components/Editor.jsx'

function App() {
return (
    <Editor/>)
}

export default App;


